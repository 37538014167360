$max: 60;
$offset: 2;
$unit: 'px'; // Feel free to change the unit.

@mixin list-loop($className, $styleName) {
  $i: 0;

  @while $i <=$max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }

    $i: $i + $offset;
  }
}

@include list-loop('.pl-', 'padding-left');
@include list-loop('.pr-', 'padding-right');
@include list-loop('.pt-', 'padding-top');
@include list-loop('.pb-', 'padding-bottom');
@include list-loop('.p-', 'padding');
@include list-loop('.ml-', 'margin-left');
@include list-loop('.mr-', 'margin-right');
@include list-loop('.mt-', 'margin-top');
@include list-loop('.mb-', 'margin-bottom');
@include list-loop('.m-', 'margin');
@include list-loop('.gap-', 'gap');
@include list-loop('.top-', 'top');
@include list-loop('.right-', 'right');
@include list-loop('.left-', 'left');
@include list-loop('.bottom-', 'bottom');

.m-auto {
  margin: auto;
}

.h-auto {
  height: auto;
}
