@use '../utils/' as *;

.auth-wrapper {
  min-width: 400px;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  .auth-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-block: 80px;
    max-width: 500px;
    margin: 0 auto;
    @include respond-to($screen767) {
      width: 300px;
    }
    .zinq-form {
      .zinq-form-item {
        label {
          font-weight: 500;
        }
      }
      .zinq-form-item-control-input {
        .zinq-form-item-control-input-content {
          .zinq-otp {
            width: 100%;
          }
        }
        .zinq-select-selector {
          .zinq-select-selection-item {
            color: var(--neutrals11);
          }
        }
        .zinq-input-group-addon {
          .country-list {
            min-width: 100px;
          }
        }
      }
    }
    .social-groups {
      max-width: 400px;
      margin: 0 auto;
      .zinq-form {
        .zinq-form-item {
          &.password-field {
            .zinq-form-item-explain-error {
              position: relative; // for longer error messages
            }
          }
        }
      }
      @include respond-to($screen767) {
        min-width: 300px;
      }
    }
  }
}
