/* Box sizing rules */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: initial;
  outline: none;
  -webkit-tap-highlight-color: transparent !important;
  // font-family:
  //   'Inter',
  //   system-ui,
  //   -apple-system,
  //   BlinkMacSystemFont,
  //   'Segoe UI',
  //   Roboto,
  //   Oxygen,
  //   Ubuntu,
  //   Cantarell,
  //   'Open Sans',
  //   'Helvetica Neue',
  //   sans-serif;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

html,
body,
div,
span,
section,
p,
a,
img,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
select,
input,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd option {
  padding: 0;
  margin: 0;
  border: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ol,
ul {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Target the entire scrollbar */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

/* Track (progress bar) */
::-webkit-scrollbar-track {
  background: var(--neutral-00); /* Color of the track */
  border-radius: 10px; /* Rounded corners */
}

/* Handle (draggable part) */
::-webkit-scrollbar-thumb {
  background: var(--neutral-05); /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovered */
}

/* Set core body defaults */
body {
  text-rendering: auto;
  line-height: 1.6;
  scroll-behavior: smooth;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'],
.pointer {
  cursor: pointer;
}

// a {
//   color: var(--neutrals9);
//   &:hover {
//     color: var(--neutrals7);
//   }
// }

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

.zinq-form-item
  .zinq-form-item-label
  > label.zinq-form-item-required:not(
    .zinq-form-item-required-mark-optional
  )::before {
  content: '';
}

.zinq-form-item
  .zinq-form-item-label
  > label.zinq-form-item-required:not(
    .zinq-form-item-required-mark-optional
  )::after {
  content: '*';
  visibility: visible;
  color: var(--danger-on-surface);
}

.zinq-form-item .zinq-form-item-explain-error {
  font-size: 12px;
  position: absolute;
}

.zinq-input-number-affix-wrapper {
  padding: 0;
  min-width: 100%;
}

// To remove bottom default margins
.zinq-typography {
  &.mb-0 {
    margin-bottom: 0;
  }
}

.zinq-upload-list-item-actions {
  .zinq-btn.zinq-btn-text {
    box-shadow: none;
    background: transparent;
  }
}

.zinq-select-dropdown {
  .zinq-select-item {
    border-radius: 16px;
    &.zinq-select-item-option-active {
      border-radius: 16px;
    }
  }
}

.zinq-upload-wrapper {
  .zinq-upload {
    &.zinq-upload-disabled {
      button {
        &.zinq-btn-text {
          opacity: 0.5;
          background-color: var(--surface-secondary);
          color: var(--content-primary);
        }
      }
    }
  }
}

.zinq-color-picker-trigger {
  background-color: var(--surface-primary);
  border-radius: 16px;
  border: 1px solid var(--border-primary);
  &.zinq-color-picker-lg {
    border-radius: 16px;
    padding: 6px;
    .zinq-color-picker-color-block {
      height: 30px;
      width: 30px;
      border-radius: 8px;
    }
  }
}
