@use '../utils' as *;

// common tabs for tag
.zinq-tag {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0;
  &.zinq-tag-default {
    padding: 4px 8px;
    white-space: normal;
  }
  &.zinq-tag-warning {
    padding: 4px 8px;
    white-space: normal;
  }
  > .anticon + span {
    margin-inline-start: 0;
  }
}
