@use '../utils' as *;

.zinq-drawer {
  &.zinq-drawer-right {
    .zinq-drawer-content-wrapper {
      max-width: calc(100vw - 40px);
      width: 800px;
      background-color: transparent;
      border-radius: 16px 0 0 16px;
      .widget-drawer {
        border-radius: 16px 0 0 16px;
        .zinq-drawer-header {
          padding: 0;
          border: none;
          .zinq-drawer-header-title {
            .zinq-drawer-close {
              position: absolute;
              left: -34px;
              top: 10px;
              &:hover {
                background-color: transparent;
              }
            }
          }
        }
        .zinq-drawer-body {
          padding: 0;
        }
      }
    }
  }
  &.zinq-drawer-left {
    .zinq-drawer-content-wrapper {
      max-width: calc(100vw - 40px);
      width: 800px;
      border-radius: 0 16px 16px 0;
      .widget-drawer {
        border-radius: 0 16px 16px 0;
        .zinq-drawer-header {
          padding: 0;
          border: none;
          .zinq-drawer-header-title {
            .zinq-drawer-close {
              position: absolute;
              right: -34px;
              top: 10px;
              margin: 0;
              &:hover {
                background-color: transparent;
              }
            }
          }
        }
        .zinq-drawer-body {
          padding: 0;
        }
      }
    }
  }
}
