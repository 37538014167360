@use '../utils' as *;

.zinq-menu {
  &.app-sidebar {
    padding: 8px;
    border-radius: 0px 24px 24px 0px;
    &.zinq-menu-light {
      border: 0;
    }
    .zinq-menu-item {
      background-color: var(--neutrals);
      margin-bottom: 8px;
      border-radius: 12px;
      display: flex;
      padding-inline: 18px;
      align-items: center;
      @include bodyBase(500);
      .zinq-menu-item-icon {
        font-size: 20px;
      }
      svg {
        flex-shrink: 0;
      }
      &:hover,
      &.zinq-menu-item-selected {
        svg {
          path {
            stroke: var(--primary);
          }
        }
        .enable-fill {
          svg {
            path {
              fill: var(--primary);
              stroke: inherit;
            }
          }
        }
      }
    }
  }
}
