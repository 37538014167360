@use '../utils' as *;
.quill {
  .ql-toolbar {
    &.ql-snow {
      border-color: var(--border-primary);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }
  .ql-container {
    background-color: var(--surface-secondary);
    height: 300px;
    &.ql-snow {
      border-color: var(--border-primary);
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .ql-editor {
      font-size: 14px;
      font-family: var(--font-primary);
    }
  }
}
