.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
  &.medium {
    font-weight: 600;
  }
}
