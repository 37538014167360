@use '../utils' as *;

.zinq-checkbox-wrapper {
  &.primary {
    .zinq-checkbox {
      .zinq-checkbox-inner {
        height: 18px;
        width: 18px;
        border: 2px solid var(--content-primary);
        border-radius: 2px;
        &::after {
          border-color: var(--neutral-00);
          height: 10px;
        }
      }
      &.zinq-checkbox-disabled {
        .zinq-checkbox-inner {
          opacity: 0.4;
        }
      }
    }
  }
  &.secondary {
    .zinq-checkbox {
      .zinq-checkbox-inner {
        height: 18px;
        width: 18px;
        border: 2px solid var(--content-primary);
        border-radius: 2px;
        &::after {
          border: var(--neutral-06);
          height: 10px;
        }
      }
      &.zinq-checkbox-disabled {
        .zinq-checkbox-inner {
          opacity: 0.4;
        }
      }
    }
  }
}
