// common tabs for app
.app-tabs {
  .zinq-tabs-nav {
    .zinq-tabs-nav-wrap {
      .zinq-tabs-nav-list {
        .zinq-tabs-tab {
          .zinq-tabs-tab-btn {
            text-shadow: none;
          }
        }
      }
    }
  }
}
