.zinq-popover {
  .zinq-popover-content {
    .zinq-popover-inner {
      padding: 16px;
      .zinq-popover-inner-content {
        .zinq-popconfirm-inner-content {
          .zinq-popconfirm-message {
            margin-bottom: 16px;
          }
          .zinq-popconfirm-buttons {
            display: flex;
            gap: 8px;
            .zinq-btn {
              padding: 16px 8px;
              margin-inline-start: 0;
              border-radius: 16px;
              &.zinq-btn-default {
                background-color: var(--neutrals1);
                border: 0;
                &:hover {
                  color: var(--neutrals11);
                }
              }
            }
          }
        }
      }
    }
  }
}
