@use '../utils/' as *;

.form-controls {
  .zinq-form-item {
    .zinq-form-item-label > label {
      height: auto;
    }
    .zinq-checkbox-group {
      display: flex;
      gap: 8px;
      .zinq-checkbox-wrapper {
        background-color: transparent;
        border-radius: 50px;
        padding: 4px 16px;
        border: 2px solid var(--neutrals);
        color: var(--neutrals);
        .zinq-checkbox {
          display: none;
        }
        &:hover {
          background-color: var(--neutrals);
          color: var(--neutrals11);
        }
        &.zinq-checkbox-wrapper-checked {
          background-color: var(--neutrals);
          color: var(--neutrals11);
        }
      }
    }
    .zinq-btn {
      &.btn-secondary {
        border-radius: 50px;
        color: var(--neutrals);
        background: transparent;
        border: 2px solid var(--neutrals);
        padding: 8px 24px;
        &:hover {
          background: transparent;
          opacity: unset;
          background-color: var(--neutrals);
          color: var(--neutrals11);
        }
      }
    }

    .zinq-form-item-control-input {
      .zinq-input-outlined,
      .zinq-select-selector,
      .zinq-input-number {
        box-shadow: none;
        border-bottom: 1px solid;
        border-right: 0;
        width: 100%;
      }
      .zinq-picker {
        width: 100%;
        max-width: 400px;
        box-shadow: none;
        &.zinq-picker-outlined {
          border-top: 0;
          border-right: 0;
          border-left: 0;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .zinq-input-number-group-wrapper {
        width: 100%;
        .zinq-input-number-group-addon {
          background-color: transparent;
          border-radius: 0;
          border-bottom: 1px solid !important;
        }
      }
      .zinq-select {
        min-width: 100px;
        .zinq-select-selector {
          border-bottom: 1px solid;
          .zinq-select-selection-item {
            color: unset;
          }
        }
      }
    }
    .zinq-input-affix-wrapper {
      .zinq-input-show-count-suffix {
        position: absolute;
        bottom: -26px;
        right: 0;
      }
    }

    .zinq-input-textarea-show-count {
      .zinq-input-data-count {
        position: absolute;
        bottom: -26px;
        right: 0;
      }
    }

    .zinq-rate {
      font-size: 48px;
      .zinq-rate-star {
        &.zinq-rate-star-zero {
          opacity: 0.4;
        }
      }
    }

    .suffix-icon-textarea {
      right: 12px;
      bottom: 10px;
    }

    .zinq-input-number-affix-wrapper {
      padding: 0;
      min-width: 100%;
      box-shadow: none;
      .zinq-input-number-suffix {
        pointer-events: all;
        margin: 0;
      }
      &:focus {
        box-shadow: none;
      }
      &::before {
        width: 12px;
      }
    }

    .zinq-slider {
      .zinq-slider-mark {
        .zinq-slider-mark-text {
          top: 10px;
        }
      }
    }

    #time-picker-container {
      .zinq-picker-dropdown {
        .zinq-picker-time-panel-column {
          li {
            &.zinq-picker-time-panel-cell-selected {
              .zinq-picker-time-panel-cell-inner {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    .zinq-upload-wrapper {
      .zinq-upload-list {
        .zinq-upload {
          width: auto;
          &.zinq-upload-select {
            background-color: transparent;
            border: 0;
          }
        }
        .zinq-upload-list-item-container {
          .zinq-upload-list-item {
            .zinq-upload-list-item-actions {
              .zinq-btn {
                &.zinq-btn-text {
                  box-shadow: none;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    .zinq-form-item-explain-error {
      margin-top: 6px;
    }
  }
}

.auth-carousel {
  padding: 40px;
  height: 100vh;
  overflow-y: hidden;
  background: linear-gradient(40deg, #efeffb 7.29%, #f8ecf8 97.9%);
  position: sticky;
  top: 0;
  .zinq-carousel {
    .slick-dots {
      li {
        width: unset;
        &.slick-active {
          button {
            height: 8px;
            width: 8px;
            background: var(--content-primary);
            border-radius: 50%;
          }
        }
        button {
          height: 8px;
          width: 8px;
          background: var(--content-quarternary);
          border-radius: 50%;
        }
      }
    }
  }
  .slide {
    height: calc(100vh - 28px);
    .text-description {
      max-width: 450px;
    }
    img {
      width: 100%;
      height: 430px;
      margin: 0 auto;
      object-fit: cover;
    }
  }
}
