@use '../utils' as *;

.zinq-btn {
  transition: all 0.5s ease;
  &.zinq-btn-primary {
    box-shadow:
      0px 17px 7px 0px rgba(0, 0, 0, 0.01),
      0px 10px 6px 0px rgba(0, 0, 0, 0.06),
      0px 4px 4px 0px rgba(0, 0, 0, 0.12),
      0px 1px 2px 0px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(32, 32, 32, 0.1),
      0px 1px 0px 0px rgba(32, 32, 32, 0.1);
    &:disabled {
      opacity: 0.5;
      background-color: var(--surface-inverse-primary);
      color: var(--neutrals);
    }
  }
  &.zinq-btn-default {
    box-shadow:
      0px 1px 2px 0px rgba(23, 27, 28, 0.1),
      0px 0px 0px 1px rgba(23, 27, 28, 0.1);
    &:disabled {
      opacity: 0.5;
      background-color: var(--surface-primary);
      color: var(--content-primary);
    }
    &.gradient-border {
      background: linear-gradient(180deg, #f9f8f6 0%, #f9cfff 100%);
      position: relative;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 25px;
      z-index: 1;
      box-shadow:
        0px 17px 7px 0px rgba(0, 0, 0, 0.01),
        0px 10px 6px 0px rgba(0, 0, 0, 0.06),
        0px 4px 4px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 1px rgba(32, 32, 32, 0.1),
        0px 1px 0px 0px rgba(32, 32, 32, 0.1);
      @include bodyBase(600);
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        background: linear-gradient(90deg, #543ed8, #ff62fd);
        border-radius: inherit;
        z-index: -1;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--surface-primary);
        border-radius: inherit;
        z-index: -1;
        transition: all 0.3s ease;
      }
    }
    &.coming-soon-button {
      background-color: #543ed81f;
      box-shadow: none;
      span {
        opacity: 0.9;
        background: linear-gradient(
          177deg,
          #543ed8 -8.72%,
          #bb79f0 123.35%,
          #f2b1fd 144.85%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
      &:hover {
        opacity: 0.9;
        background-color: #543ed81f;
      }
    }
    &.widget-button {
      box-shadow:
        0px 1px 2px 0px rgba(23, 27, 28, 0.1),
        0px 0px 0px 1px rgba(23, 27, 28, 0.1);
      height: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      &:hover {
        opacity: 0.9;
      }
    }
    &.sidetab-button {
      box-shadow:
        0px 1px 2px 0px rgba(23, 27, 28, 0.1),
        0px 0px 0px 1px rgba(23, 27, 28, 0.1);
      right: 0;
      position: absolute;
      top: 50%;
      left: unset;
      min-width: 100px;
      max-width: max-content;
      height: auto;
      padding: 12px 8px;
      display: flex;
      flex-direction: column-reverse;
      min-width: auto;
      transform: translate(0, -50%);
      transition: all 380ms ease;
      .zinq-btn-icon {
        display: block;
      }
      .btn-vertical-text {
        writing-mode: vertical-lr;
        line-height: 1;
        transform: scale(-1);
        display: block;
      }
      &:hover {
        opacity: 0.9;
      }
      &.open {
        transition: transform 400ms ease;
        transform: translate(-400px, -50%);
      }
    }
    &.popover-button {
      box-shadow:
        0px 1px 2px 0px rgba(23, 27, 28, 0.1),
        0px 0px 0px 1px rgba(23, 27, 28, 0.1);
      bottom: 24px;
      right: 24px;
      position: absolute;
      &:hover {
        opacity: 0.9;
        background-color: #543ed81f;
        transform: scale(1.1);
      }
    }
  }
  &.zinq-btn-text {
    box-shadow:
      0px 1px 2px 0px rgba(23, 27, 28, 0.1),
      0px 0px 0px 1px rgba(23, 27, 28, 0.1);
    background-color: var(--surface-secondary);
    &:hover {
      background-color: var(--surface-tertiary);
    }
    &:disabled {
      opacity: 0.5;
      background-color: var(--surface-secondary);
      color: var(--content-primary);
    }
  }
  &.shadow-hidden {
    box-shadow: none;
  }
  &.bg-surface-tertiary {
    background-color: var(--surface-tertiary);
  }
  .zinq-btn-icon {
    display: flex;
  }
}

.brand-btn-primary {
  border-radius: 160px;
  background: var(--Background-1, #543ed8);
  padding: 16px 20px;
  box-shadow:
    0px 0px 0px 0.717px rgba(255, 255, 255, 0.5) inset,
    0px 0.717px 0px 0px #fff inset,
    0px 13px 8px 0px rgba(0, 0, 0, 0.05),
    0px 7.167px 6px 0px rgba(0, 0, 0, 0.08),
    0px 2.867px 4px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 0.8px rgba(32, 32, 32, 0.1),
    0px 0.717px 0px 0px rgba(32, 32, 32, 0.1),
    0px 0px 0px 0.717px rgba(255, 255, 255, 0.5) inset;
  color: var(--content-inverse-primary);
  font-weight: 600;
  font-family: var(--font-secondary);
  font-size: 16px;
  border: 0;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
}

.brand-btn-secondary {
  border-radius: 160px;
  background: linear-gradient(180deg, #f9f8f6 0%, #f9cfff 100%);
  padding: 16px 20px;
  box-shadow:
    0px 13px 8px 0px rgba(0, 0, 0, 0.05),
    0px 7.167px 6px 0px rgba(0, 0, 0, 0.08),
    0px 2.867px 4px 0px rgba(0, 0, 0, 0.12),
    0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 0.8px rgba(32, 32, 32, 0.1),
    0px 0.717px 0px 0px rgba(32, 32, 32, 0.1),
    0px 0px 0px 0.717px rgba(255, 255, 255, 0.5) inset,
    0px 0.717px 0px 0px #fff inset;
  font-size: 16px;
  font-family: var(--font-secondary);
  font-weight: 600;
  border: 0;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
  .btn-text {
    background: linear-gradient(180deg, #bd7fc7 0%, #7f388a 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.floating-next-prev-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  .zinq-btn {
    &.zinq-btn-default {
      border: 0;
      box-shadow: none;
      border-radius: 40px 0px 0px 40px;
      padding: 10px 8px 10px 12px;
      &:last-child {
        padding: 10px 12px 10px 8px;
        border-radius: 0px 40px 40px 0px;
      }
      &.dark {
        background-color: #1a1a1a1a;
      }
      &.light {
        background-color: #ffffff1a;
      }
      &:disabled {
        opacity: 0.5;
        color: unset;
      }
    }
  }
}

.floating-start-again-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  .zinq-btn {
    height: 38px;
    &.zinq-btn-default {
      border: 0;
      box-shadow: none;
      border-radius: 50px;
      padding: 18px;
      @include bodyMeta();
      &.dark {
        background-color: #1a1a1a1a;
      }
      &.light {
        background-color: #ffffff1a;
      }
      &:disabled {
        opacity: 0.5;
        color: unset;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
