.form-fields-pagination {
  border-radius: 50px;
  border: 1px solid var(--border-primary, #e5e8e9);
  background: var(--surface-secondary, #f5f6f7);
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.08),
    0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(32, 32, 32, 0.06);
  width: max-content;
  padding: 4px;
  .zinq-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .zinq-pagination-prev,
  .zinq-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: var(--surface-primary, #fff);
    &.zinq-pagination-disabled {
      opacity: 0.6;
    }
  }
  .zinq-pagination-item-active {
    border: 0;
  }
}
