@use '../utils/' as *;

.zinq-modal {
  &.subscribe-modal {
    &.upgrade-Successful {
      .zinq-modal-content {
        h1 {
          font-size: 32px;
          line-height: 38px;
          margin-bottom: 16px;
        }
      }
    }
    .zinq-modal-close {
      color: #fff;
    }
    .cover-img {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
    .subscribe-modal-wrapper {
      position: relative;
      z-index: 1;
      .gradient-text {
        background: linear-gradient(
          175.34deg,
          #543ed8 -7.37%,
          #bb79f0 86.02%,
          #f2b1fd 101.22%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    .zinq-modal-content {
      padding: 40px 48px;
      background: #0b0b23;
      border-radius: 16px;
      position: relative;
      overflow: hidden;
      h1 {
        text-align: center;
        font-family: var(--font-secondary);
        font-size: 52px;
        font-weight: 600;
        line-height: 62.4px;
        letter-spacing: -0.03em;
        text-align: center;
      }
      .subscribe-plan-card-wrapper {
        display: flex;
        gap: 32px;
        margin-top: 36px;
        align-items: flex-start;
        .subscribe-plan-card {
          background: #d6d6d61a;
          padding: 16px;
          flex: 1;
          border-radius: 24px;
          border: 5px solid transparent;
          transition: all 0.3s ease-in-out;
          color: var(--content-inverse-quarternary);
          &:hover {
            border: 5px solid #ffffff1f;
            background: #ededed33;
            color: var(--content-inverse-primary);
          }
          .tag {
            border: 2px solid var(--border-primary);
            border-radius: 50px;
            padding: 4px 16px;
            @extend .text-sm;
            @extend .semi-bold;
            display: inline-flex;
            align-items: center;
            gap: 8px;
            text-transform: uppercase;
            svg {
              vertical-align: middle;
            }
            &.premium {
              background: linear-gradient(
                  117deg,
                  #543ed8 23.28%,
                  #bb79f0 131.9%
                ),
                linear-gradient(0deg, #120b3b 0%, #120b3b 100%), #dac2f1;

              border: 2px solid #fff;
              color: var(--content-inverse-secondary);
              font-weight: 400;
            }
          }
          .plan-label {
            color: var(--content-inverse-secondary);
            @include headingH1(600);
          }
          ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            li {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
          .plan-button {
            display: flex;
            justify-content: center;
            gap: 16px;
            margin-top: 16px;
            .zinq-btn {
              height: 40px;
              padding: 10px 16px;
              border-radius: 50px;
              @include bodyS;
              &.zinq-btn-text {
                background: transparent;
                color: var(--content-inverse-primary);
                text-decoration: underline;
                font-weight: 500;
                border: 0;
                box-shadow: none;
              }
              &.zinq-btn-primary {
                background: linear-gradient(180deg, #f9f8f6 0%, #f9cfff 100%);
                font-weight: 600;
                span {
                  background: linear-gradient(180deg, #bd7fc7 0%, #7f388a 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                }
              }
            }
            .plan-button-account-page {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .subscribe-modal-footer-text {
        color: var(--content-inverse-quarternary);
      }
    }
  }
}

.zinq-modal {
  &.form-selection-modal {
    .zinq-modal-header {
      background: transparent;
    }
    .zinq-modal-content {
      border-radius: 20px;
      background: var(--surface-tertiary);
      box-shadow:
        5.547px 27.04px 15.947px 0px rgba(0, 0, 0, 0.04),
        2.773px 11.787px 11.787px 0px rgba(0, 0, 0, 0.06),
        1.387px 2.773px 5.547px 0px rgba(0, 0, 0, 0.08);
      padding: 24px;
      .card-wrapper {
        border-radius: 20px;
        padding: 16px;
        position: relative;
        background: var(--surface-secondary, #f5f6f7);
        overflow: hidden;
        box-shadow:
          0px 4px 4px 0px rgba(0, 0, 0, 0.08),
          0px 1px 2px 0px rgba(0, 0, 0, 0.1),
          0px 0px 0px 1px rgba(32, 32, 32, 0.06);
        .card-icon {
          position: absolute;
          bottom: 0;
          right: 0;
          display: flex;
        }
      }
    }
  }
}

.zinq-modal {
  &.common-key-modal {
    .zinq-modal-header {
      background: transparent;
    }
    .zinq-modal-content {
      border-radius: 20px;
      box-shadow:
        5.547px 27.04px 15.947px 0px rgba(0, 0, 0, 0.04),
        2.773px 11.787px 11.787px 0px rgba(0, 0, 0, 0.06),
        1.387px 2.773px 5.547px 0px rgba(0, 0, 0, 0.08);
      padding: 24px;
      .domain-record-table {
        margin: 24px 0 8px;
        .zinq-table-container {
          .zinq-table-content {
            table {
              border: 1px solid var(--border-primary);
              border-radius: 16px;
              .zinq-table-thead {
                border-radius: 16px;
                .zinq-table-cell {
                  background: var(--surface-secondary);
                  @include bodyS(400);
                  color: var(--content-tertiary);
                  &:first-child {
                    border-top-left-radius: 16px;
                  }
                  &:last-child {
                    border-top-right-radius: 16px;
                  }
                  &::before {
                    display: none;
                  }
                }
              }
              .zinq-table-tbody {
                .zinq-table-row {
                  .zinq-table-cell {
                    .zinq-alert {
                      width: max-content;
                      padding: 4px 8px;
                      &.zinq-alert-warning {
                        .zinq-alert-icon {
                          margin-inline-end: 2px;
                        }
                      }
                      &.zinq-alert-success {
                        border: 1px solid var(--success-on-surface);
                        background: var(--success-surface);

                        .zinq-alert-icon {
                          margin-inline-end: 2px;
                        }
                      }
                    }
                    .domain-verification-status-skeleton {
                      width: 90px;
                      min-width: unset;
                    }
                  }
                  &:last-child {
                    .zinq-table-cell {
                      &:first-child {
                        border-bottom-left-radius: 16px;
                      }
                      &:last-child {
                        border-bottom-right-radius: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .zinq-modal-footer {
      margin-top: 16px;
    }
  }
}

.zinq-modal {
  &.publish-alert-modal {
    .zinq-modal-header {
      background: transparent;
    }
    .zinq-modal-content {
      border-radius: 20px;
      box-shadow:
        5.547px 27.04px 15.947px 0px rgba(0, 0, 0, 0.04),
        2.773px 11.787px 11.787px 0px rgba(0, 0, 0, 0.06),
        1.387px 2.773px 5.547px 0px rgba(0, 0, 0, 0.08);
      padding: 24px;
    }
    .zinq-modal-footer {
      margin-top: 16px;
    }
  }
}

.zinq-modal {
  &.review-question-modal {
    .zinq-modal-header {
      background: transparent;
    }
    .zinq-modal-content {
      border-radius: 20px;
      box-shadow:
        5.547px 27.04px 15.947px 0px rgba(0, 0, 0, 0.04),
        2.773px 11.787px 11.787px 0px rgba(0, 0, 0, 0.06),
        1.387px 2.773px 5.547px 0px rgba(0, 0, 0, 0.08);
      padding: 24px;
    }
    .zinq-modal-body {
      .zinq-checkbox-wrapper {
        .add-question-review-checkbox {
          border-radius: 16px;
          border: 1px solid var(--border-primary);
          background: var(--surface-primary);
          padding: 8px;
          margin-left: 4px;
        }
        .zinq-checkbox + span {
          width: 100%;
        }
      }
    }
    .zinq-modal-footer {
      margin-top: 16px;
    }
  }
  &.invite-member-modal {
    .zinq-modal-body {
      .emails-list {
        .zinq-select-selector {
          .zinq-select-selection-overflow {
            row-gap: 4px;
            .zinq-select-selection-item {
              border-radius: 16px;
              padding: 0 16px;
            }
          }
        }
      }
    }
  }
}

.zinq-modal {
  &.purchase-success-modal {
    .zinq-modal-header {
      background: transparent;
    }
    .zinq-modal-content {
      border-radius: 16px;
      background: #09091d;
      background-image: url('../../assets/images/brand-linear-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      .upgrade-success-content {
        .gradient-text {
          background: linear-gradient(
            175.34deg,
            #543ed8 -7.37%,
            #bb79f0 86.02%,
            #f2b1fd 101.22%
          );
          text-align: center;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin-top: 0;
        }
        .animate-text {
          animation: slideUp 0.5s ease forwards;
          @keyframes slideUp {
            0% {
              transform: translateY(20px);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }
          &.hidden {
            display: none; /* Hides the text */
          }
          &.visible {
            display: flex; /* Shows the text */
          }
        }
      }
    }
  }
}

.zinq-modal {
  &.remove-domain-modal {
    .zinq-modal-content {
      .zinq-modal-body {
        .domain-remove-alert {
          list-style: disc;
          padding-left: 16px;
          li {
            @include bodyS(500);
            color: var(--content-secondary);
          }
        }
      }
    }
  }
}

.zinq-modal {
  &.activity-log-modal {
    .zinq-modal-header {
      background: transparent;
      padding: 16px;
      margin-bottom: 0;
      .button-wrapper {
        border-radius: 8px;
        border: 1px solid var(--border-primary);
        background: var(--surface-primary);
        .navigation-button {
          border-radius: 0px;
          border: 0;
          padding: 0;
          height: 36px;
          width: 36px;
          &.left {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          &.right {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
    .zinq-modal-content {
      border-radius: 20px;
      box-shadow:
        5.547px 27.04px 15.947px 0px rgba(0, 0, 0, 0.04),
        2.773px 11.787px 11.787px 0px rgba(0, 0, 0, 0.06),
        1.387px 2.773px 5.547px 0px rgba(0, 0, 0, 0.08);
      padding: 0;
      .zinq-modal-body {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        .activity-record-table {
          .zinq-table {
            overflow: hidden;
            border-radius: 0;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            .zinq-table-container {
              .zinq-table-header {
                border-radius: 0;
                table {
                  border: 1px solid var(--border-primary);
                  border-radius: 0;
                  .zinq-table-thead {
                    .zinq-table-cell {
                      background: var(--surface-secondary);
                      @include bodyS(400);
                      color: var(--content-tertiary);
                      border-radius: 0;
                      &::before {
                        display: none;
                      }
                    }
                  }
                  .zinq-table-tbody {
                    .zinq-table-row {
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            .zinq-table-body {
              .zinq-table-tbody {
                .zinq-table-row {
                  cursor: pointer;
                }
              }
            }
          }
        }
        .webhook-details-wrapper {
          padding: 0 16px 16px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          .webhook-summary {
            border: 1px solid var(--border-primary);
            padding: 16px;
            border-radius: 16px;
          }
          .editor-block {
            padding: 12px;
            border-radius: 8px;
            background: var(--surface-primary);
            border: 1px solid var(--border-primary);
            pre {
              max-height: 400px;
              overflow-y: auto;
              .language-json {
                span {
                  white-space: pre-wrap;
                }
              }
            }
            .code-divider {
              border-block-start: 1px solid var(--border-primary);
              margin: 12px 0;
            }
            &.dark {
              border: 1px solid var(--border-primary);
              background: var(--surface-inverse-primary);
              .code-divider {
                border-block-start: 1px solid var(--border-secondary);
              }
            }
          }
        }
      }
    }
    .zinq-modal-footer {
      margin-top: 16px;
    }
    &.log-details {
      .zinq-modal-content {
        padding-bottom: 16px;
      }
    }
  }
}

.zinq-modal {
  &.widget-modal {
    .zinq-modal-content {
      padding: 0;
      height: 80vh;
      border-radius: 16px;
      overflow: unset;
      .zinq-modal-close {
        top: -34px;
        right: 0;
        &:hover {
          background: transparent;
        }
      }
      .zinq-modal-body {
        height: 100%;
        .form-preview-mode {
          border-radius: 16px;
        }
      }
    }
  }
}
