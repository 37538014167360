@use '../utils' as *;

.zinq-switch {
  &.primary {
    background-color: var(--surface-tertiary);
    .zinq-switch-handle {
      &::before {
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.24);
      }
    }
    &.zinq-switch-checked {
      background-color: var(--surface-inverse-primary);
      .zinq-switch-inner {
        height: 18px;
        width: 18px;
        &::after {
          border-color: var(--neutral-00);
          height: 10px;
        }
      }
      &.zinq-switch-disabled {
        background-color: var(--surface-inverse-tertiary);
        opacity: 0.4;
        .zinq-switch-handle {
          &::before {
            box-shadow: none;
          }
        }
      }
    }
    &.zinq-switch-disabled {
      background-color: var(--surface-tertiary);
      opacity: 0.7;
      .zinq-switch-handle {
        &::before {
          box-shadow: none;
        }
      }
    }
  }
  &.secondary {
    background-color: var(--surface-tertiary);
    &.zinq-switch-checked {
      background-color: var(--surface-inverse-primary);
      .zinq-switch-inner {
        height: 18px;
        width: 18px;
        &::after {
          border-color: var(--neutral-06);
          height: 10px;
        }
      }
    }
    &.zinq-switch-disabled {
      background-color: var(--surface-secondary);
    }
  }
}
