@use '../utils' as *;

.auth-layout {
  .auth-container {
    height: 100vh;
    display: table;
    background-color: var(--neutral-00);
    position: relative;
    margin: 0 auto;
    width: 100%;
  }
  .img-container {
    height: 100vh;
    overflow: hidden;
    @include respond-to($screen991) {
      display: none;
    }
  }
}

.screen-layout {
  .zinq-layout-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f5f6;
    height: 100vh;
    overflow-y: auto;
  }
}

.app-layout {
  .zinq-layout-content {
    padding: 24px 32px;
    background: var(--neutrals);
    height: calc(100vh - 64px);
    overflow-y: auto;
    background-color: var(--background);
    &.middle {
      display: table;
      .middle-container {
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
      }
    }
  }
}

.form-layout {
  .zinq-layout-content {
    background: var(--neutrals);
    height: 100vh;
    overflow-y: hidden;
  }
}

// css to take element middle of the parent element
.content-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.form-view-layout {
  .zinq-layout-content {
    background: var(--neutrals);
    height: 100vh;
    overflow-y: auto;
    @include respond-to($screen767) {
      padding: 16px;
    }
  }
}

.resizable-layout {
  display: flex;
  width: 100%;
  height: 100vh;
  padding-top: 64px;
  overflow: hidden;
  .left-container,
  .right-container {
    overflow: hidden;
    padding: 16px 18px 0;
    height: 100%;
  }
  .middle-container {
    width: 1px;
    cursor: col-resize;
    background: #f0f0f0;
    position: relative;
    .middle-line {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 28px;
      background: var(--neutrals2);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      user-select: none;
    }
  }
}
