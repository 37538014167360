@use '../utils' as *;
.account-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  .account-nav {
    background-color: #fff;
    padding: 16px;
    border: 1px solid var(--border-primary);
    border-radius: 24px;
    max-width: 320px;
    width: 100%;
    position: sticky;
    top: 0;
    max-height: calc(100vh - 112px);
    overflow: auto;
    .zinq-menu {
      .zinq-menu-item {
        .zinq-menu-title-content {
          font-weight: 500;
        }
        &.zinq-menu-item-disabled {
          .zinq-menu-title-content {
            color: var(--content-tertiary);
          }
        }
      }
    }
    .zinq-menu-submenu {
      .zinq-menu-submenu-title {
        .zinq-menu-title-content {
          font-weight: 500;
        }
      }
      .zinq-menu-item {
        .zinq-menu-title-content {
          font-weight: 500;
          color: var(--content-primary);
        }
      }
    }
  }
  .account-plan-detail {
    padding: 24px;
    background-color: #fff;
    border: 1px solid var(--border-primary);
    border-radius: 24px;
    flex: 1;
    .subscribe-modal.zinq-modal {
      .zinq-modal-content {
        padding: 0;
        background: transparent;
        .subscribe-plan-card-wrapper {
          gap: 32px;
          align-items: normal;
          margin-top: 0;
          .subscribe-plan-card {
            background: var(--content-inverse-secondary);
            color: var(--content-secondary);
            border: 0;
            padding: 24px;
            &:hover {
              color: var(--content-secondary);
            }
            .tag {
              &.premium {
                background: linear-gradient(
                  175.34deg,
                  #543ed8 -7.37%,
                  #bb79f0 86.02%,
                  #f2b1fd 101.22%
                );
                border: 0;
                color: #fff;
              }
            }
            .zinq-tag {
              &.current-plan-tag {
                border-radius: 50px;
                padding: 6px 12px;
                color: var(--content-primary);
              }
            }
            ul {
              li {
                @extend .text-sm;
                @extend .medium;
              }
            }
            .plan-button-account-page {
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
              .zinq-btn {
                border: 1px solid var(--content-primary);
              }
            }
          }
        }
        .subscribe-modal-footer-text {
          color: var(--content-tertiary);
        }
      }
    }
    .feature-wrapper {
      .feature-table {
        position: relative;
        &::before {
          content: '';
          width: 30%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 8px;
          background: linear-gradient(180deg, #f1effb 0%, #f8ecf8 100%);
        }
        &.hide-before {
          &::before {
            display: none;
          }
        }
      }
      table {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        thead {
          tr {
            th {
              width: 33.33%;
              padding: 20px 12px;
              border-bottom: 1px solid var(--border-secondary);
              @include bodyBase(500);
              svg {
                vertical-align: middle;
              }
              &:first-child {
                text-align: left;
              }
            }
          }
        }
        tbody {
          tr {
            th {
              padding: 32px 12px 12px 12px;
              text-align: left;
              border-bottom: 1px solid var(--border-secondary);
              @extend .text-sm;
              @extend .semi-bold;
              @extend .text-content-primary;
            }
            td {
              padding: 10px 32px;
              @extend .text-sm;
              @extend .medium;
              @extend .text-content-primary;
              text-align: center;
              &:first-child {
                text-align: left;
                font-weight: 400;
              }
            }
          }
        }
        tfoot {
          td {
            padding: 10px 0;
          }
        }
      }
    }
  }
}
