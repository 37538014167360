@use '../utils/mixin' as *;

// Title class
h1,
.h1 {
  &.zinq-typography {
    line-height: 38px;
    margin: 0;
    &.medium {
      font-weight: 500;
    }
    &.normal {
      font-weight: 400;
    }
  }
}

h2,
.h2 {
  &.zinq-typography {
    line-height: 32px;
    margin: 0;
    &.medium {
      font-weight: 500;
    }
  }
}

h3,
.h3 {
  &.zinq-typography {
    line-height: 30px;
    margin: 0;
    &.medium {
      font-weight: 500;
    }
  }
}

h4,
.h4 {
  &.zinq-typography {
    line-height: 28px;
    margin: 0;
    &.medium {
      font-weight: 500;
    }
  }
}

h5,
.h5 {
  &.zinq-typography {
    line-height: 24px;
    margin: 0;
    &.medium {
      font-weight: 500;
    }
  }
}

// Body class
.text-lg {
  @include bodyL;
}

.text-m {
  @include bodyM;
}

.text-base {
  @include bodyBase;
}

.text-sm {
  @include bodyS;
}

.text-meta {
  @include bodyMeta;
}

.text-micro {
  @include bodyMicro;
}
