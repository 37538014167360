@use '../utils/' as *;

.profile-form {
  max-width: 500px;
  width: 100%;
  .zinq-form-item {
    .zinq-form-item-control-input {
      .zinq-upload-wrapper {
        .zinq-upload-list {
          .zinq-upload-list-item-container {
            .zinq-upload-list-item {
              border: 0;
            }
          }
        }
      }
    }
  }
}

.workspace-members {
  .workspace-members-list {
    .zinq-table-wrapper {
      .zinq-table-content {
        .zinq-table-tbody {
          .zinq-table-row {
            .zinq-table-cell {
              .role-list {
                width: 100px;
              }
            }
          }
        }
      }
      #role-list-dropdown {
        .zinq-select-dropdown {
          min-width: 320px;
        }
      }
      .zinq-table-pagination {
        margin-top: 16px;
      }
    }
  }
}

.lifetime-purchase-banner {
  display: flex;
  justify-content: center;
  .lifetime-purchase-content {
    border-radius: 16px;
    background: #09091d;
    background-image: url('../../assets/images/brand-linear-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    padding: 40px;
    .gradient-text {
      background: linear-gradient(
        175.34deg,
        #543ed8 -7.37%,
        #bb79f0 86.02%,
        #f2b1fd 101.22%
      );
      text-align: center;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-top: 0;
    }
  }
}

.api-keys-wrapper {
  .domain-record-table {
    .zinq-table-container table > thead > tr:first-child > *:first-child {
      border-start-start-radius: 16px;
    }
    .zinq-table-container table > thead > tr:first-child > *:last-child {
      border-start-end-radius: 16px;
    }
    .zinq-table-container {
      .zinq-table-content {
        table {
          .zinq-table-thead {
            .zinq-table-cell {
              background: var(--surface-secondary);
              color: var(--content-tertiary);
              border: 1px solid var(--border-primary);
              border-left: 0;
              border-right: 0;
              @include bodyS(400);
              &::before {
                display: none;
              }
              &:last-child {
                border-right: 1px solid var(--border-primary);
              }
              &:first-child {
                border-left: 1px solid var(--border-primary);
              }
            }
          }
          .zinq-table-tbody {
            .zinq-table-row {
              .zinq-table-cell {
                border: 1px solid var(--border-primary);
                border-left: 0;
                border-right: 0;
                border-bottom: 0;
                .api-key-input {
                  border-radius: 8px;
                  border: 1px solid var(--border-secondary);
                  padding: 10px 8px;
                  min-width: 300px;
                  max-width: max-content;
                }
                &:last-child {
                  border-right: 1px solid var(--border-primary);
                }
                &:first-child {
                  border-left: 1px solid var(--border-primary);
                }
              }
              &:first-child {
                .zinq-table-cell {
                  border-top: 0;
                }
              }
              &:last-child {
                .zinq-table-cell {
                  border-bottom: 1px solid var(--border-primary);
                  &:first-child {
                    border-bottom-left-radius: 16px;
                  }
                  &:last-child {
                    border-bottom-right-radius: 16px;
                  }
                }
              }
            }
            .zinq-table-placeholder {
              .zinq-table-cell {
                border: 1px solid var(--border-primary);
                border-top: 0;
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
              }
            }
          }
        }
      }
    }
  }
}
