// common tour for app
.zinq-tour {
  .zinq-tour-content {
    .zinq-tour-inner {
      .zinq-tour-header {
        padding: 8px;
      }
      .zinq-tour-description {
        padding-right: 42px;
      }
      .zinq-tour-footer {
        padding: 16px;
        align-items: center;
        .zinq-tour-buttons {
          .zinq-tour-next-btn {
            background-color: var(--content-primary);
            border-radius: 50px;
            height: 32px;
            padding: 8px 16px;
          }
          .zinq-tour-prev-btn {
            background-color: var(--neutrals);
            border-radius: 50px;
            border: 1px solid var(--border-primary);
            height: 32px;
            padding: 8px 16px;
            &:hover {
              color: inherit;
            }
          }
        }
      }
    }
  }
}
