@use '../utils' as *;

.made-with-zinq {
  position: absolute;
  right: 20px;
  bottom: 20px;
  border-radius: 50px;
  background: rgba(26, 26, 26, 0.1);
  padding: 10px 12px;
  max-height: 40px;
  &.dark {
    background-color: #1a1a1a1a;
  }
  &.light {
    background-color: #ffffff1a;
  }
}

.text-coming-soon {
  background-color: #543ed81f;
  padding: 0 8px;
  border-radius: 12px;
  span {
    background: linear-gradient(
      180deg,
      #543ed8 -8.72%,
      #bb79f0 123.35%,
      #f2b1fd 144.85%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include bodyMeta(500);
  }
}
