@use '../utils/mixin' as *;

.flex {
  @include flexbox;
}
.flex-row {
  @include flex-direction($value: row);
}

.flex-column {
  @include flex-direction($value: column);
}

.justify-center {
  @include justify-content($value: center);
}

.justify-start {
  @include justify-content($value: flex-start);
}

.justify-end {
  @include justify-content($value: end);
}

.justify-between {
  @include justify-content($value: space-between);
}

.justify-around {
  @include justify-content($value: space-around);
}

.items-center {
  @include align-items($value: center);
}

.items-start {
  @include align-items($value: flex-start);
}

.items-end {
  @include align-items($value: end);
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.wrap {
  @include flex-wrap($value: wrap);
}