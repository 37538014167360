@use 'variable' as *;

// # Neutral classes
.text-neutrals {
  color: var(--neutral-00);
}
.text-neutrals-1 {
  color: var(--neutral-01);
}
.text-neutrals-2 {
  color: var(--neutral-02);
}
.text-neutrals-3 {
  color: var(--neutral-03);
}
.text-neutrals-4 {
  color: var(--neutral-04);
}
.text-neutrals-5 {
  color: var(--neutral-05);
}
.text-neutrals-6 {
  color: var(--neutral-06);
}
.text-neutrals-7 {
  color: var(--neutral-07);
}
.text-neutrals-8 {
  color: var(--neutral-08);
}
.text-neutrals-9 {
  color: var(--neutral-09);
}
.text-neutrals-10 {
  color: var(--neutral-10);
}
.text-blue {
  color: var(--system-blue);
}
.text-system-blue-dark {
  color: var(--system-blue-dark);
}
.text-system-blue-light {
  color: var(--system-blue-light);
}
.text-system-green-dark {
  color: var(--system-green-dark);
}
.text-system-green-light {
  color: var(--system-green-light);
}
.text-system-red-dark {
  color: var(--system-red-dark);
}
.text-system-red-light {
  color: var(--system-red-light);
}
.text-system-yellow-dark {
  color: var(--system-yellow-dark);
}
.text-system-yellow-light {
  color: var(--system-yellow-light);
}
.text-content-secondary {
  color: var(--content-secondary);
}
.text-content-tertiary {
  color: var(--content-tertiary);
}
.text-content-primary {
  color: var(--content-primary);
}
.text-danger-on-surface {
  color: var(--danger-on-surface);
}
.text-content-quarternary {
  color: var(--content-quarternary);
}
.text-content-inverse-secondary {
  color: var(--content-inverse-secondary);
}
.text-content-inverse-primary {
  color: var(--content-inverse-primary);
}

.text-caution-on-surface {
  color: var(--caution-on-surface);
}
// # Surface classes
.bg-transparent {
  background-color: transparent;
}
.bg-content-tertiary {
  background-color: var(--content-tertiary);
}
.bg-surface-primary {
  background-color: var(--surface-primary);
}
.bg-surface-secondary {
  background-color: var(--surface-secondary);
}
.bg-surface-tertiary {
  background-color: var(--surface-tertiary);
}
.bg-content-inverse-primary {
  background-color: var(--content-inverse-primary);
}

// Modes
.zinq-typography,
.zinq-tag {
  &.text-light {
    color: var(--content-inverse-primary);
  }
  &.text-dark {
    color: var(--content-primary);
  }
}

.bg-dark {
  background-color: var(--surface-inverse-secondary);
}

.bg-light {
  background-color: var(--surface-secondary);
}
