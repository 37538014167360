@use '../utils/' as *;

.zinq-alert {
  &.zinq-alert-warning {
    display: flex;
    justify-content: center;
    background-color: var(--caution-surface);
    border: 1px solid var(--caution-on-surface);
    .zinq-alert-content {
      flex: unset;
      .zinq-alert-message {
        color: var(--content-secondary);
        @include bodyS(500);
      }
    }
  }

  &.zinq-alert-error {
    display: flex;
    justify-content: center;
    background-color: var(--danger-surface);
    border: 1px solid var(--danger-on-surface);
    .zinq-alert-content {
      flex: unset;
      .zinq-alert-message {
        color: var(--content-secondary);
        @include bodyS(500);
      }
    }
    .zinq-alert-close-icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 16px;
    }
  }

  &.zinq-alert-info {
    display: flex;
    justify-content: center;
    background-color: var(--surface-tertiary);
    border: 1px solid var(--border-secondary);
    .zinq-alert-content {
      flex: unset;
      .zinq-alert-message {
        color: var(--content-secondary);
        @include bodyS(500);
      }
    }
    .zinq-alert-close-icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 16px;
    }
  }

  &.zinq-alert-success {
    display: flex;
    justify-content: center;
    background-color: var(--success-surface);
    border: 1px solid var(--success-on-surface);
    .zinq-alert-content {
      flex: unset;
      .zinq-alert-message {
        color: var(--content-secondary);
        @include bodyS(500);
      }
    }
    .zinq-alert-close-icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 16px;
    }
  }

  &.form-input-alert {
    margin-top: 8px;
  }
  .zinq-alert-icon {
    flex-shrink: 0;
  }

  &.small {
    svg {
      margin-inline-end: 2px;
    }
    padding: 4px 8px;
    width: max-content;
    &.zinq-alert-warning {
      .zinq-alert-content {
        .zinq-alert-message {
          @include bodyMeta(500);
        }
      }
    }
    &.zinq-alert-success {
      .zinq-alert-content {
        .zinq-alert-message {
          @include bodyMeta(500);
        }
      }
    }
    &.zinq-alert-error {
      .zinq-alert-content {
        .zinq-alert-message {
          @include bodyMeta(500);
        }
      }
    }

    &.zinq-alert-info {
      .zinq-alert-content {
        .zinq-alert-message {
          @include bodyMeta(500);
        }
      }
    }
  }
}
