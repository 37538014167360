@use '../utils/' as *;
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=DM+Serif+Text:ital@0;1&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.container {
  max-width: $container;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  @include respond-to($screen1170) {
    padding: 0 20px;
  }

  @include respond-to($screen767) {
    padding: 0 16px;
  }
}

.container-fluid {
  width: 100%;
  margin: auto;
  padding: 0 24px;
  @include respond-to($screen1170) {
    padding: 0 20px;
  }

  @include respond-to($screen767) {
    padding: 0 16px;
  }
}

.page-flex {
  display: inline-flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  .wrapper {
    flex: 1;
  }
}
