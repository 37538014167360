@use '../utils/breakpoint' as *;

// common segment for app
.app-segments {
  padding: 4px;
  border-radius: 50px;
  width: 100%;
  overflow-x: auto;
  // hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .zinq-segmented-group {
    display: flex;
    gap: 8px;
    .zinq-segmented-item,
    .zinq-segmented-thumb {
      max-height: 40px;
      display: flex;
      justify-content: center;
      padding: 10px 14px;
      align-items: center;
      border-radius: 50px;
      width: 100%;
      .zinq-segmented-item-label {
        display: flex;
        .zinq-segmented-item-icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &.zinq-segmented-item-selected {
        box-shadow:
          0px 4px 4px 0px rgba(0, 0, 0, 0.08),
          0px 1px 2px 0px rgba(0, 0, 0, 0.1),
          0px 0px 0px 1px rgba(32, 32, 32, 0.06);
        border-radius: 50px;
      }
      &:hover {
        border-radius: 50px;
      }
    }
  }
  &.rounded {
    .zinq-segmented-group {
      .zinq-segmented-item {
        padding: 12px;
        .zinq-segmented-item-label {
          padding: 0;
        }
      }
    }
  }
  &.w-auto {
    width: auto;
  }
}
.vertical-segments {
  padding: 4px;
  border-radius: 12px;
  width: 100%;
  background: var(--surface-secondary);
  overflow-x: auto;
  // hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .zinq-segmented-group {
    display: flex;
    gap: 8px;
    .zinq-segmented-item,
    .zinq-segmented-thumb {
      max-height: auto;
      display: flex;
      justify-content: center;
      padding: 10px 24px;
      align-items: center;
      border-radius: 12px;
      width: 100%;
      @include respond-to($screen1199) {
        padding: 8px;
      }
      .zinq-segmented-item-label {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .zinq-segmented-item-icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &.zinq-segmented-item-selected {
        box-shadow:
          0px 4px 4px 0px rgba(0, 0, 0, 0.08),
          0px 1px 2px 0px rgba(0, 0, 0, 0.1),
          0px 0px 0px 1px rgba(32, 32, 32, 0.06);
        border-radius: 12px;
      }
      &:hover {
        border-radius: 12px;
      }
    }
  }
  &.embed-share-type {
    .zinq-segmented-item,
    .zinq-segmented-thumb {
      max-height: auto;
      display: flex;
      justify-content: center;
      padding: 8px 0;
      align-items: center;
      border-radius: 12px;
      width: 100%;
      @include respond-to($screen1199) {
        padding: 8px;
      }
      .zinq-segmented-item-label {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .zinq-segmented-item-icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &.zinq-segmented-item-selected {
        box-shadow:
          0px 4px 4px 0px rgba(0, 0, 0, 0.08),
          0px 1px 2px 0px rgba(0, 0, 0, 0.1),
          0px 0px 0px 1px rgba(32, 32, 32, 0.06);
        border-radius: 12px;
      }
      &:hover {
        border-radius: 12px;
      }
    }
  }
}
