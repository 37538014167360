@use './breakpoint' as *;

// Body
@mixin bodyL($weight: 400) {
  font-size: 20px;
  font-style: normal;
  font-weight: $weight;
  line-height: 30px;
}

@mixin bodyM($weight: 400) {
  font-size: 18px;
  font-style: normal;
  font-weight: $weight;
  line-height: 28px;
}

@mixin bodyBase($weight: 400) {
  font-size: 16px;
  font-style: normal;
  font-weight: $weight;
  line-height: 24px;
}

@mixin bodyS($weight: 400) {
  font-size: 14px;
  font-style: normal;
  font-weight: $weight;
  line-height: 20px;
}

@mixin bodyMeta($weight: 400) {
  font-size: 12px;
  font-style: normal;
  font-weight: $weight;
  line-height: 18px;
}

@mixin bodyMicro($weight: 400) {
  font-size: 10px;
  font-style: normal;
  font-weight: $weight;
  line-height: 16px;
}

// headings
@mixin headingH1($weight: 400) {
  font-size: 32px;
  font-style: normal;
  font-weight: $weight;
  line-height: 38px;
}

@mixin headingH2($weight: 400) {
  font-size: 24px;
  font-style: normal;
  font-weight: $weight;
  line-height: 30px;
}

@mixin headingH3($weight: 400) {
  font-size: 20px;
  font-style: normal;
  font-weight: $weight;
  line-height: 26px;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

// Flex cross browser mixin*/
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}
@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}
@mixin flex-wrap($value: nowrap) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex($fg: 1, $fs: 0, $fb: auto) {
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so lets grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box: $fg-boxflex;
  -moz-box: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}
