@use '../utils' as *;

.app-header {
  height: 64px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  border-bottom: 1px solid var(--border-primary);
  #workspace-menu-list {
    .zinq-select-dropdown {
      cursor: pointer;
    }
  }
  .menu-list {
    .zinq-select-selector {
      .zinq-select-selection-item {
        padding-inline-end: 32px;
      }
    }
  }
  .image-avatar {
    border: 1px solid var(--border-primary);
  }
}
